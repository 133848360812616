import { Box, Button, Snackbar, TextField, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import Header from "../../components/Header";

const AddBot = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    console.log(values);

    const formData = new FormData();
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("assistantId", values.assistantId);
    formData.append("description", values.description);
    if (values.image) {
      formData.append("imageURL", values.image);
    }

    try {
      const response = await fetch(
        "https://bkblackapi.hasthiya.org/botuser/addBot",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const text = await response.text();
        throw new Error(`Network response was not ok: ${text}`);
      }

      const data = await response.json();
      console.log(data);
      if (data.success) {
        setAlertMessage("Bot added successfully.");
        setAlertSeverity("success");
        setOpenAlert(true);
        setTimeout(() => {
          navigate("/botslogs");
        }, 2000);
      } else {
        setAlertMessage("Failed to add bot.");
        setAlertSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setAlertMessage("An error occurred. Please try again.");
      setAlertSeverity("error");
      setOpenAlert(true);
    }
  };

  return (
    <Box m="20px" height="80vh" overflow="auto" paddingRight="20px">
      <Header title={`Add BOT`} subtitle="Add new Bot" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Typography variant="h5">Bot First Name</Typography>
              <TextField
                fullWidth
                variant="filled"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 4", mt: "-20px" }}
              />
              <Typography variant="h5">Bot Last Name</Typography>
              <TextField
                fullWidth
                variant="filled"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 4", mt: "-20px" }}
              />
              <Typography variant="h5">ID</Typography>
              <TextField
                fullWidth
                variant="filled"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.assistantId}
                name="assistantId"
                error={!!touched.assistantId && !!errors.assistantId}
                helperText={touched.assistantId && errors.assistantId}
                sx={{ gridColumn: "span 4", mt: "-20px" }}
              />
              <Typography variant="h5">Description</Typography>
              <TextField
                fullWidth
                variant="filled"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 4", mt: "-20px" }}
              />
              <Typography variant="h5">Image</Typography>
              <Box
                display="flex"
                flexDirection="column"
                gap="10px"
                sx={{ gridColumn: "span 4", mt: "-20px" }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  component="label"
                  sx={{ alignSelf: "flex-start" }}
                >
                  Upload Image
                  <input
                    type="file"
                    hidden
                    onChange={(event) => {
                      setFieldValue("image", event.currentTarget.files[0]);
                    }}
                  />
                </Button>
                {values.image && (
                  <Box display="flex" alignItems="center">
                    {/* <Typography variant="body1">{values.image.name}</Typography> */}
                    <img
                      src={URL.createObjectURL(values.image)}
                      alt="Preview"
                      style={{ marginLeft: "10px", maxWidth: "100px" }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Add Bot
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <ToastContainer />
      <Snackbar
        open={openAlert}
        autoHideDuration={2000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={handleCloseAlert}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const validationSchema = yup.object().shape({
  firstName: yup.string().required("Bot First Name is required"),
  lastName: yup.string().required("Bot Last Name is required"),
  description: yup.string().required("Bot Description is required"),
  assistantId: yup.string().required("ID is required"),
  image: yup.mixed().required("Image is required"),
});

const initialValues = {
  firstName: "",
  lastName: "",
  description: "",
  assistantId: "",
  image: null,
};

export default AddBot;
