import { Box, Button, Snackbar, TextField, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import Header from "../../components/Header";

const EditBot = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const token = localStorage.getItem("token");

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      description: "",
      image: null,
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required("Bot First Name is required"),
      lastName: yup.string().required("Bot Last Name is required"),
      description: yup.string().required("Bot Description is required"),
      image: yup.mixed().required("Image is required"),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("description", values.description);
      if (values.image && typeof values.image !== "string") {
        formData.append("imageURL", values.image);
      }

      try {
        const response = await fetch(
          `https://bkblackapi.hasthiya.org/botuser/updateBot/${id}`,
          {
            method: "PUT",

            body: formData,
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || "Network response was not ok");
        }

        if (data.success) {
          setAlertMessage("Bot updated successfully.");
          setAlertSeverity("success");
          setOpenAlert(true);
          setTimeout(() => {
            navigate("/botslogs");
          }, 2000);
        } else {
          throw new Error(data.message || "Failed to edit bot.");
        }
      } catch (error) {
        console.error("Error:", error);
        setAlertMessage(
          error.message || "An error occurred. Please try again."
        );
        setAlertSeverity("success");
        setTimeout(() => {
          navigate("/botslogs");
        }, 2000);
        setOpenAlert(true);
      }
    },
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `https://bkblackapi.hasthiya.org/botuser/getBotUser/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data && data.success && data.user) {
          formik.setValues({
            firstName: data.user.firstName || "",
            lastName: data.user.lastName || "",
            description: data.user.description || "",
            image: data.user.imageURL || null,
          });
        } else {
          console.error(
            "Failed to fetch user data or data is not in expected format"
          );
        }
      } catch (error) {
        console.error("Error fetching bot data:", error);
      }
    };

    fetchUserData();
  }, [id, token]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <Box m="20px" height="80vh" overflow="auto" paddingRight="20px">
      <Header title={`EDIT BOT ID ${id}`} subtitle="Edit Each Bot Details" />

      <form onSubmit={formik.handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <Typography variant="h5">Bot First Name</Typography>
          <TextField
            fullWidth
            variant="filled"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.firstName}
            name="firstName"
            error={!!formik.touched.firstName && !!formik.errors.firstName}
            helperText={formik.touched.firstName && formik.errors.firstName}
            sx={{ gridColumn: "span 4", mt: "-20px" }}
          />
          <Typography variant="h5">Bot Last Name</Typography>
          <TextField
            fullWidth
            variant="filled"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.lastName}
            name="lastName"
            error={!!formik.touched.lastName && !!formik.errors.lastName}
            helperText={formik.touched.lastName && formik.errors.lastName}
            sx={{ gridColumn: "span 4", mt: "-20px" }}
          />
          <Typography variant="h5">Description</Typography>
          <TextField
            fullWidth
            variant="filled"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.description}
            name="description"
            error={!!formik.touched.description && !!formik.errors.description}
            helperText={formik.touched.description && formik.errors.description}
            sx={{ gridColumn: "span 4", mt: "-20px" }}
          />
          <Typography variant="h5">Image</Typography>
          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            sx={{ gridColumn: "span 4", mt: "-20px" }}
          >
            <Button
              variant="contained"
              color="secondary"
              component="label"
              sx={{ alignSelf: "flex-start" }}
            >
              Upload Image
              <input
                type="file"
                hidden
                onChange={(event) => {
                  formik.setFieldValue("image", event.currentTarget.files[0]);
                }}
              />
            </Button>
            {formik.values.image && (
              <Box display="flex" alignItems="center">
                <img
                  src={
                    typeof formik.values.image === "string"
                      ? formik.values.image
                      : URL.createObjectURL(formik.values.image)
                  }
                  alt="Preview"
                  style={{ marginLeft: "10px", maxWidth: "100px" }}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="end" mt="20px">
          <Button type="submit" color="secondary" variant="contained">
            Save Changes
          </Button>
        </Box>
      </form>
      <ToastContainer />
      <Snackbar
        open={openAlert}
        autoHideDuration={2000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={handleCloseAlert}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditBot;
