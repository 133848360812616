import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import MuiAlert from "@mui/material/Alert";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for toastify
import * as Yup from "yup";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [userData, setUserData] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false); // State for Snackbar
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `https://bkblackapi.hasthiya.org/user/getUser/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data && data.success && data.user) {
          setUserData(data.user);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    // if (token) {
    fetchUserData();
    // }
    // }, [id, token]);
  }, []);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(
          `https://bkblackapi.hasthiya.org/user/updateUserDetails/${id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(values),
          }
        );
        const data = await response.json();
        if (data.success) {
          setOpenSnackbar(true);
          setTimeout(() => {
            setOpenSnackbar(false);
            navigate("/contacts");
          }, 2000);
        }
      } catch (error) {
        console.error("Error updating user data:", error);
      }
    },
  });

  useEffect(() => {
    if (userData) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
      }));
    }
  }, [userData]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box m="20px" height="80vh" overflow="auto" paddingRight="20px">
      <Header title={`EDIT USER ID ${id}`} subtitle="Edit Each User Details" />
      <Box sx={{ marginBottom: "20px" }}>
        {/* <Link to={`/changeuserpw/${id}`}>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            Change User Password
          </Button>
        </Link> */}
      </Box>
      {userData && (
        <form onSubmit={formik.handleSubmit}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.blueAccent[400]} variant="h5">
                First Name
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                id="firstName"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                sx={{ width: "500px" }}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.blueAccent[400]} variant="h5">
                Last Name
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                id="lastName"
                name="lastName"
                value={formik.values.lastName}
                sx={{ width: "500px" }}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.blueAccent[400]} variant="h5">
                User Email
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                sx={{ width: "500px" }}
              />
            </AccordionDetails>
          </Accordion>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button type="submit" color="secondary" variant="contained">
              Update User Account
            </Button>
          </Box>
        </form>
      )}

      {/* Success Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity="success"
          elevation={6}
          variant="filled"
        >
          User updated successfully
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditUser;
