import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Avatar,
  Box,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [contactsData, setContactsData] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await fetch(
          "https://bkblackapi.hasthiya.org/admin/getAllusersForAdmin?limit=100000",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data && data.success && data.users && data.users.data) {
          const sortedData = data.users.data.sort((b, a) => a.id - b.id);
          setContactsData(
            sortedData.map((user) => ({
              id: user.id,
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
              imageURL: user.imageURL,
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    // if (token) {
    fetchContacts();
    // }
  }, []);

  const handleEditClick = (id) => {
    console.log(`Edit clicked for with id:`, id);
  };

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this contact!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(
            `https://bkblackapi.hasthiya.org/user/deleteUser/${id}`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          console.log(data);

          setContactsData((prevData) =>
            prevData.filter((contact) => contact.id !== id)
          );

          Swal.fire("Deleted!", "User has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting user:", error);

          Swal.fire("Error", "Failed to delete user.", "error");
        }
      }
    });
  };

  const handleViewClick = (id) => {
    console.log("View button clicked for row with ID:", id);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.6, key: "id" },
    {
      field: "name",
      headerName: "Full Name",
      flex: 1,
      cellClassName: "name-column--cell",
      key: "name",
    },
    { field: "email", headerName: "Email", flex: 1, key: "email" },
    {
      field: "imageURL",
      headerName: "Profile Image",
      flex: 1,
      key: "imageURL",
      renderCell: (params) => (
        <Box>
          <Box>
            <Typography>
              {params.row.imageURL ? (
                <Avatar
                  alt={params.row.name}
                  src={params.row.imageURL}
                  style={{ width: 60, height: 60 }}
                />
              ) : (
                "No image available"
              )}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.6,
      key: "actions",
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <Link to={`/contacts/view/${params.row.id}`}>
              <IconButton onClick={() => handleViewClick(params.row.id)}>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </Tooltip>

          <Tooltip title="Edit">
            <Link to={`/contacts/edit/${params.row.id}`}>
              <IconButton onClick={() => handleEditClick(params.row.id)}>
                <EditIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box>
        <Header
          title="CONTACTS"
          subtitle="List of Contacts for All the Users"
        />

        {/* <Link to={`/addnewuser`}>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            Add New User
          </Button>
        </Link> */}
      </Box>

      <Box
        m="20px 0 0 0"
        height="60vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.blueAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={contactsData.map((row) => ({ ...row, key: row.id }))}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          rowHeight={70}
          pageSize={7}
          rowsPerPageOptions={[7]}
          pagination
          sx={{
            fontSize: "15px",
          }}
        />
      </Box>
    </Box>
  );
};

export default Contacts;
