import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";

// Utility function to capitalize the first letter
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Viewclient = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [userData, setUserData] = useState(null);
  const [status, setStatus] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `https://bkblackapi.hasthiya.org/botuser/getBotUser/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data && data.success && data.user) {
          setUserData(data.user);
          setStatus(capitalizeFirstLetter(data.user.status));
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [id, token]);

  return (
    <Box m="20px" height="80vh" overflow="auto" paddingRight="20px">
      <Header title={`VIEW BOT ID ${id}`} subtitle={`Bot Status ${status}`} />

      {userData && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.blueAccent[400]} variant="h5">
                First Name
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{userData.firstName}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.blueAccent[400]} variant="h5">
                Last Name
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{userData.lastName}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.blueAccent[400]} variant="h5">
                Description
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{userData.description}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.blueAccent[400]} variant="h5">
                Bot Image
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {userData.imageURL ? (
                  <img
                    src={userData.imageURL}
                    alt="User"
                    style={{ width: 400, height: 300 }}
                  />
                ) : (
                  "No image available"
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.blueAccent[400]} variant="h5">
                Created At (MM/DD/YYYY)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {new Date(userData.createdAt).toLocaleString()}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.blueAccent[400]} variant="h5">
                Updated At (MM/DD/YYYY)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {new Date(userData.updatedAt).toLocaleString()}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </Box>
  );
};

export default Viewclient;
