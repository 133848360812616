import { CssBaseline, ThemeProvider } from "@mui/material";
import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AddNewBot from "./scenes/bots/AddNewBot.jsx";
import EditBot from "./scenes/bots/EditBot.jsx";
import ViewBot from "./scenes/bots/ViewBot.jsx";
import Botslogs from "./scenes/bots/index.jsx";
import ChangePW from "./scenes/changepw/Index.jsx";
import Contacts from "./scenes/contacts";
import AddNewUser from "./scenes/contacts/AddNewUser.jsx";
import ChangeUserPW from "./scenes/contacts/ChangeUserPW.jsx";
import EditUser from "./scenes/contacts/EditUser";
import ViewUser from "./scenes/contacts/ViewUser";
import Dashboard from "./scenes/dashboard";
import Sidebar from "./scenes/global/Sidebar";
import Topbar from "./scenes/global/Topbar";
import Signup from "./scenes/signup/index.jsx";
import { ColorModeContext, useMode } from "./theme";
import PrivateRoutes from "./utils/PrivateRoutes.js";
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();

  const isLoginPage = location.pathname === "/";

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {!isLoginPage && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {!isLoginPage && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route element={<PrivateRoutes />}>
                <Route path="/dd" element={<Dashboard />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/contacts/view/:id" element={<ViewUser />} />
                <Route path="/contacts/edit/:id" element={<EditUser />} />
                <Route path="/changeuserpw/:id" element={<ChangeUserPW />} />
                <Route path="/changepw" element={<ChangePW />} />
                <Route path="/botsLogs" element={<Botslogs />} />
                <Route path="/addnewuser" element={<AddNewUser />} />
                <Route path="/addbot" element={<AddNewBot />} />
                <Route path="/viewbot/:id" element={<ViewBot />} />
                <Route path="/editbot/:id" element={<EditBot />} />
              </Route>
              <Route path="/" element={<Signup />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
